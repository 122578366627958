import React from 'react'
import TimeEntry from './TimeEntry'
import { ThreeDots } from 'react-loader-spinner'

export default function TimeList({ timeEntries, loading }) {

  if (loading) {
    return (<div class="flex w-full align-middle justify-center"><ThreeDots
      height="80"
      width="80"
      color="#facc15"
      ariaLabel="circles-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    /></div>)
  }

  return (
    timeEntries.map(timeEntry => {
      return (<TimeEntry key={timeEntry._id} timeEntry={timeEntry} />)
    })
  )
}