import React from 'react'
import getShortDate from './getShortDate'

export default function TimeEntry({ timeEntry }) {
    return (
        <div class="relative flex w-full gap-2 text-left">
            <div class="flex-none tabular-nums">
                <p>{getShortDate(timeEntry.date)}</p>
            </div>
            <div class="flex-grow">
                <p>{timeEntry.description}</p>
            </div>
            <div class="flex-none">{timeEntry.hours}h</div>
        </div>
    )
}
