import React, { useState, useEffect, useCallback } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import TimeList from './TimeList'
import InvoiceList from './InvoiceList'
import getTotalHours from './getTotalHours'
import InvoiceDetails from './InvoiceDetails'
import ErrorFallback from './ErrorFallback'
import './index.css'

function App() {

  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState([])

  useEffect(() => {
    if (token.length === 0) {
      getToken().then((response) => {
        setToken(response.token)
        setLoading(false)
      }).catch(error => console.log(error))
    }
  }, [loading, token])

  const getToken = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        /** Greetings, Im glad you found the apikey. However, to spare you some time, it can only access public functions. */
        'apiKey': 'd4f6ZcDJ5ddpWe'
      })
    })

    let json = await response.json()
    return json
  }

  if (!loading && token) {
    return (
      <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route path="/:customerSecret" element={<InvoiceData authToken={token} />} />
          </Routes>
        </ErrorBoundary>
      </>
    )
  }
}

function InvoiceData({ authToken }) {

  const params = useParams()

  const [timeEntries, setTimeEntries] = useState([])
  const [customer, setCustomer] = useState([])
  const [invoices, setInvoices] = useState([])
  const [filteredEntries, setFilteredEntries] = useState([])
  const [invoice, setInvoice] = useState([])
  const [invoiceId, setInvoiceId] = useState(0)
  const [loading, setLoading] = useState(true)
  const [invoiceLoading, setInvoiceLoading] = useState(true)
  

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/public/gettimeentries?custid=${params.customerSecret}`, {
      method: 'GET',
      headers: { 'Authorization': authToken }
    })
      .then(response => response.json())
      .then(jsonData => {
        setTimeEntries(jsonData)
        setLoading(false)
      }).catch(error => {
        console.log(error)
      })
  }, [params.customerSecret, authToken, loading])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/public/getcustomer?custid=${params.customerSecret}`, {
      method: 'GET',
      headers: { 'Authorization': authToken }
    })
      .then(response => response.json())
      .then(jsonData => {
        setCustomer(jsonData)
      }).catch(error => {
        console.log(error)
      })
  }, [params.customerSecret, authToken])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/public/getallinvoicesbycustomer?custid=${params.customerSecret}`, {
      method: 'GET',
      headers: { 'Authorization': authToken }
    })
      .then(response => response.json())
      .then(jsonData => {
        setInvoices(jsonData)
        return jsonData
      }).then(invoices => {
        let firstInvoice = invoices.find(x => (x !== undefined && x._id !== 0))
        setInvoiceId(firstInvoice._id)
      }).catch(error => {
        console.log(error)
      })
  }, [params.customerSecret, authToken])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/public/getinvoicebycustomer?invoiceid=${invoiceId}&custid=${params.customerSecret}`, {
      method: 'GET',
      headers: { 'Authorization': authToken }
    })
      .then((response, error) => {
        if (response.status !== 200)
          throw error
        return response.json()
      })
      .then(jsonData => {
        setInvoice(jsonData)
      }).catch(() => {
        setInvoice('')
      }).finally(() => {
        setInvoiceLoading(false)
      })
  }, [invoiceId, authToken, params.customerSecret])

  useEffect(() => {
    let entries = Object.values(timeEntries).filter(x => x.invoiceId === (Number.parseInt(invoiceId)))
    setFilteredEntries(entries)
  }, [timeEntries, invoiceId])

  const handleInvoiceSwitch = useCallback((e) => {

    setLoading(true)
    setInvoiceLoading(true)
    const getSelectedId = (e) => {
      let option = e.target.childNodes[e.target.selectedIndex].getAttribute('id')
      return option || 0
    }

    let id = getSelectedId(e)
    setInvoiceId(id)
    setFilteredEntries(Object.values(timeEntries).filter(x => x.invoiceId === (Number.parseInt(id))))
  }, [timeEntries])

  return (
    <>
      <div class="flex flex-col items-center mx-auto text-center">
        <h1 class="text-3xl print:text-xl">HKonsult AB - Faktureringsunderlag</h1>
        <div class="flex flex-col content-center w-full">
          <div class="text-left">

            <p class="mt-1 text-2xl text-center print:text-base">{customer.Name} {customer.OrganisationNumber}</p>

            <div class="h-[8em]">
              <InvoiceDetails invoice={invoice} invoiceLoading={invoiceLoading} />
            </div>

            <div class="flex mt-6 mb-2 align-middle print:hidden">
              <div class="w-2/3 text-left">
                <label class="text-lg leading-tight text-white">Period:
                  <select class="dateselector" onChange={handleInvoiceSwitch}>
                    <InvoiceList invoices={invoices} />
                  </select >
                </label >
              </div>
            </div>
            <span class="hidden mt-10 mb-2 text-lg print:text-sm print:block">Period:</span>
            <hr class="mb-4 border-yellow-400 print:border-gray-300" />
            <div class="table w-full border-collapse">
              <div class="table-row-group">
                <TimeList timeEntries={filteredEntries} loading={loading} />
              </div>
            </div>
            <p class="mt-2 text-lg print:text-sm">Totalt: {getTotalHours(filteredEntries)} h</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default App