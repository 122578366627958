import React from 'react'
import getShortDate from './getShortDate'

export default function Invoice(invoice) {
  return (
      <option id={invoice.invoice._id}>
      {invoice.invoice._id === 0 ? "Ofakturerad" : getShortDate(invoice.invoice.firstDate) + " till " + getShortDate(invoice.invoice.lastDate) + " #" + invoice.invoice._id}
      </option>
  )
}
