import React from 'react'
import { ThreeDots } from 'react-loader-spinner'


export default function InvoiceDetails({ invoice, invoiceLoading }) {

    if (invoiceLoading)
        return (<div class="flex w-full align-middle justify-center"><ThreeDots
            height="80"
            width="80"
            color="#facc15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        /></div>)

    if (invoice.DocumentNumber) {
        return (
            <>
                <p class="mt-2 text-xl text-center print:text-base print:text-black print:bg-white">
                    Fakturanummer: {invoice.DocumentNumber}</p>
                <p class="mt-2 text-xl text-center print:text-base print:text-black print:bg-white">
                    Förfallodatum: {invoice.DueDate}</p>
                <p class={`mt-2 text-xl font-bold ${invoice.Balance === 0 ? 'text-green-500' : 'text-red-500'} text-center print:text-base print:hidden`}>
                    {invoice.Balance === 0 ? 'BETALD' : 'EJ BETALD'}</p>
            </>
        )
    }
    return (
        <p class="mt-2 text-xl text-center text-red-500 font-bold print:text-base print:text-black print:bg-white">OFAKTURERAT</p>
    )
}
