import React from 'react'
import Invoice from './Invoice'

export default function InvoiceList({ invoices }) {

    return (
        invoices.map(invoice => {
            if (invoice._id !== 0)
                return (<Invoice key={invoice._id} invoice={invoice} />)
            return null
        })
    )
}
